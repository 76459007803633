import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

function DefaultCover() {
  const DefImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "default_cover.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return <Img fluid={DefImage.file.childImageSharp.fluid} />
}

export default DefaultCover
