import React from "react"
import {
  Link
} from "gatsby"
const Pagination = ({
  pageContext
}) => {
  const {
    previousPagePath,
    nextPagePath,
    numberOfPages,
    humanPageNumber,
  } = pageContext

  const onlyOne = () => {
    if (numberOfPages > 1) {
      return false
    } else {
      return true
    }
  }

  const PagesText = onlyOne() ? "Page" : "Pages"

  return ( <div className = "pagination" >
    <ul> {previousPagePath && ( 
      <li className = "prev" >
        <Link to = {`/${previousPagePath}`} > Previous </Link> </li>
    )
    } <li className = "catName" > {
      humanPageNumber + " of " + numberOfPages + " " + PagesText
    } </li> {
      nextPagePath && ( 
        <li className = "next" >
        <Link to = {`/${nextPagePath}`} > Next </Link> </li>
      )
    } </ul></div>
  )
}
export default Pagination