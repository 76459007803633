import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import DefImage from "../components/page-components/default-cover"
import Pagination from "../components/partials/pagination"
import Seo from "../components/partials/seo"
import CategoryBanner from "../components/page-components/banner/category-page-ads"

function categoryTemplate({ data, pageContext }) {
  const catName = pageContext.name
  return (
    <Layout>
      <CategoryBanner />

          <Seo title={catName + ' | ' + data.site.siteMetadata.title}/>
      <div className="archive-header">
        <h1>{catName}</h1>
      </div>
      <div className="cards-container">
        {data.allWpPost.edges.map(({ node }) => {
          const date = new Date(node.date)

          const dateTimeFormat = new Intl.DateTimeFormat("en", {
              year: "numeric",
              month: "short",
              day: "2-digit",
          })
          const [
            { value: month },
            ,
            { value: day },
            ,
            { value: year },
          ]= dateTimeFormat.formatToParts(date)
        
          return (
            <div className="cards" key={node.id}>
              <Link to={node.uri}>
                <div className="cards-image">
                  {node.featuredImage ? (
                    <Img
                      fluid={
                        node.featuredImage.node.localFile.childImageSharp.fluid
                      }
                      objectFit="fill"
                      objectPosition="50% 50%"
                    />
                  ) : (
                    <DefImage />
                  )}
                </div>
                <div className="cards-content">
                  <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
                  <span className="cards-content--from_now">{month} {day} {year}</span>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
      <Pagination pageContext={pageContext} />
      <CategoryBanner />

    </Layout>
  )
}

export default categoryTemplate

export const categoryQuery = graphql`
  query($name: String, $skip: Int!, $limit: Int!) {
    allWpPost(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: date }
      filter: { categories: { nodes: { elemMatch: { name: { eq: $name } } } } }
    ) {
      edges {
        node {
          id
          uri
          title
          excerpt
          slug
          date
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 680) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
